@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities{
  .primary-blue{
    color: #1C548E;
  }

  .light-blue{
    color: #379BD2;
  }

  .dark-blue {
    color: #181E4B;
  }

  .dark-blue-inactive {
    color: #181E4B;
    opacity: 0.3;
  }

  .job-progress-bar{
    background-color: #181E4B;
  }

  .table-background{
    background: rgba(55, 155, 210, 0.1);
  }

  .table-right-border{
    border-right: 0.5px solid #5E6282;
  }

  .table-bottom-border{
    border-bottom: 0.5px solid #5E6282;
  }

  .light-gray {
    color: #7A7A7A;
  }

  .progress-bar-text{
    font-size: 0.625rem;
    line-height: 1rem;
  }

  .text-rs-pink{
    color: rgba(211, 26, 132, 1);
  }

  .text-programs-yellow{
    color: rgba(189, 124, 31, 1);
  }

  .light-pink {
    color: rgba(225, 97, 142, 1);
  }

  .hired-yellow-text{
    color: rgba(182, 108, 2, 1);
  }

  .content-text-blue{
    color: #5E6282;

  }

  .edit-button-container{
    border: 1px solid rgba(94, 98, 130, 0.5);
    border-radius: 12px;
  }

  .review-header-container{
    border-bottom: 1px solid rgba(94, 98, 130, 0.5);
  }

  .content-pink{
    color: #D31A84;
  }

  .choose-file-container{
    border: 0.5px solid #1C548E;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .job-post-card{
    background: rgba(94, 98, 130, 0.05);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }

  .service-header-inactive{
    color: rgba(24, 30, 75, 0.2)
  }

  .service-header-active{
    color: rgba(24, 30, 75, 1);
  }

  .text-pink{
    color: rgba(211, 26, 132, 1);
  }

  .service-footer-margin{
    margin-top: 20rem;
  }

  .notice-padding{
    padding-top: 20rem;
    padding-bottom: 6rem;
  }

  .notice-padding-mobile{
    padding-top: 21rem;
    padding-bottom: 5rem;
  }

  .oah-culture-padding{
    padding-bottom: 20rem;
  }

  .job-application-height{
    height: 24rem;
  }

  .vertical-line {
    border-left: 2px solid #379BD2;
    height: 170px;
    position: relative;
    left: 50%;
    margin-left: -3px;
    top: 0;
  }

  .services-header {
    top: 4.15rem;
  }

  .service-notice-header {
    top: 7.50rem;
  }

  .job-apply-header {
    top: 4.15rem;
  }

  @media only screen and (min-width: 768px) {
    .service-footer-margin{
      margin-top: 48rem;
    }

    .oah-culture-padding{
      padding-bottom: 48rem;
    }

    .notice-padding{
      padding-top: 42rem;
      padding-bottom: 20rem;
    }

    .job-application-height{
      height: 36rem;
    }

    .vertical-line {
      border-left: 2px solid #379BD2;
      height: 90px;
      position: relative;
      left: 50%;
      margin-left: -3px;
      top: 0;
    }

    .services-header {
      top: 5.5rem;
    }

    .service-notice-header {
      top: 10.0rem;
    }

    .job-apply-header {
      top: 5.5rem;
    }
  }

  .underline-offset{
    text-underline-offset: 3px;
  }

  .service-header-active-border{
    border-color: rgba(24, 30, 75, 1);
  }

  .navbar-background{
    background: linear-gradient(92.22deg, rgba(115, 212, 255, 0.2) -48.51%, #FFFFFF 100%);
  }

  .hero-button-background{
    background: linear-gradient(86.82deg, #1C548E 0%, #379BD2 100%);
    border-radius: 85px;
  }

  .hero-image-overlay{
      right: 12%;
      bottom: 15%;
  }

  .next-image-overlay{
    right: 2%;
    bottom: 50%;
  }

  .prev-image-overlay{
    left: 2%;
    bottom: 50%;
  }

  .left-hero-image-overlay{
    left: 12%;
    bottom: 20%;
  }

  .background-light-blue{
    background: rgba(55, 155, 210, 0.1);
  }

  .background-light-pink{
    background: rgba(255, 245, 251, 1);
  }

  .background-oah-culture{
    background: rgba(255, 222, 241, 1);
  }

  .background-rs-pink{
    background: rgba(253, 231, 244, 1);
  }

  .background-programs{
    background: rgba(255, 242, 224, 1);
  }

  .background-service-desc{
    background: #EDF9FF;
  }

  .background-oah-process{
    background: #FFD193;
  }

  .form-notification-container{
    background: rgba(94, 98, 130, 0.05);
    border-radius: 0px 4px 4px 0px;
    border-left: 2px solid #D31A84;
  }

  .background-light-gray{
    background: rgba(94, 98, 130, 0.05);
  }

  .menu-background{
    background: linear-gradient(0deg, #1DA8FB 0%, #FF82B7 46.35%, #FB9B43 91.15%);
    opacity: 0.2;
  }

  .footer-background{
    background: #1C548F;
  }

  .submit-button-container {
    background: linear-gradient(82.9deg, #E1618E 0%, #F09866 100%);
    box-shadow: 0px 10px 25px 10px rgba(241, 165, 1, 0.1);
    border-radius: 30px;
  }

  .back-button-container {
    border: 0.5px solid #000000;
    border-radius: 30px;
  }

  .learn-more-button-container{
    background: linear-gradient(83.66deg, #1C548E 0%, #379BD2 100%);
    border-radius: 47px;
  }

  .menu-text{
    color: #212832 !important;
  }

  .menu-border{
    border-color: #212832;
  }

  .job-header-border{
    border-left: 2px solid #379BD2;
  }
}

@font-face {
  font-family: 'Volkhov';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/volkhov/v12/SlGQmQieoJcKemNecTUEhQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.poweredByText{
  line-height: 2;
}